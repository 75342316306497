import React, { useState, useEffect } from 'react';
import { useLocation } from "@reach/router";
import {auth, firestore}  from 'firebase';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import 'fontsource-roboto';
import './App.css';

import * as firebase from 'firebase';

require("firebase/functions");

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#61C1A1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ef6c00',
      contrastText: '#fff',
    },
  }
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    paddingTop: '100%', // 1:1 
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleIcon: {
    flexGrow: 1,
  },
  header: {
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: '#ef6c00',
    color: '#fafafa'
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    color: '#ef6c00'
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  footerRowItem: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center'
  },
  copyright: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#ef6c00'
  },
  loading: {
    paddingTop: theme.spacing(3), 
    paddingBottom: theme.spacing(3),
  },
  padTop: {
    paddingTop: theme.spacing(3),
  },
  padBottom: {
    paddingBottom: theme.spacing(1),
  },
  padMargins: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    alignItems: 'center',
    alignContent: 'center'
  },
  dialogTitle: {
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#61C1A1',
    color: '#fafafa'
  },
  dialogClose: {
    paddingLeft: theme.spacing(3),
    justifyContent: 'left'
  },
  dialogItem: {
    flexDirection: 'column',
    justifyContent: 'top',
    paddingTop: theme.spacing(2)
  },
  stripeButton: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  stripeCallToAction: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  stripeFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }

});


function AuthStripe(props) {

  const classes = useStyles();

  // used to read response from stripe
  const location = useLocation();
  const queryString = require('query-string');

  // initalize stripe
  //const stripe = useStripe();
  

  // initialize firestore
  const db = firestore();  
  var user = auth().currentUser;
  if (!user) {
    auth().signInAnonymously().catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('App - Auth error ', errorCode, ': ', errorMessage);
        alert('Authentication error: ' + errorMessage);
    });
  }

  const [isComplete, setIsComplete] = useState(false);

  const [headerText, setHeaderText] = useState('-');
  const [messageText, setMessageText] = useState('-');
  const [footerText, setFooterText] = useState('-');

  //DEBUG
  const [dstate, setDstate] = useState('**');
  const [dcode, setDcode] = useState('**');
  const [derror, setDerror] = useState('**');
  const [dmessage, setDmessage] = useState('**');

  
  useEffect(() => {

    // extract params from querystring
    const parsed = queryString.parse(location.search);
    console.log('AuthStripe ==> Parsed querystring is ', parsed);

    const parsedState = parsed.state ? parsed.state : "none"  // user id
    const parsedCode = parsed.code;                   // stripe code to save
    const parsedError = parsed.error;                 // error code/type
    const parsedMessage = parsed.error_description;   // error message

    //DEBUG
    setDstate(parsedState);
    setDcode(parsedCode);
    setDerror(parsedError);
    setDmessage(parsedMessage);

    console.log("parsedState ", parsedState );
    console.log("parsedCode ", parsedCode );
    console.log("parsedError ", parsedError );
    console.log("parsedMessage ", parsedMessage );
    console.log("Parse test is ",(parsedError == null) ? "TRUE" : "FALSE");

    if (parsedError == null) {
      console.log('No error - checking for soovineer...');

      // ensure the state returned is a valid user.
      // TODO: see if there's a way to know this is the RIGHT valid user
      var ref = db.collection('soovineers').doc(parsedState)
      ref.get()
      .then(function(result) {
        if (result.exists) {
          console.log('soovineer found - calling stripe...');

          // Call firebase function to send the authorization code to Stripe's API.
          var ackConnectSignup = firebase.functions().httpsCallable('ackConnectSignup');
          ackConnectSignup({
            stripeCode: parsedCode,
            userid: parsedState
            }).then(function(result) {
            console.log('ackConnectSignup firebase function returned ', result);
            
            if (result.data.success === "true") {
              console.log('Stripe token success!');
              // account updated, all done!
              setHeaderText("Setup Complete!");
              setMessageText("Congratulations! You've successfully connected your Stripe account with soovi. You're ready to start uploading photos and earning money!");
              setFooterText("You're all done here. Please return to the Settings page.");
              setIsComplete(true);
            } else {
              console.log('Stripe token error - bailing...');
              // There was a problem
              setHeaderText("Oops! We've encountered a problem.");
              setMessageText("Unfortunately, the process did not complete successfully.  Please contact us at help@getsoovi.com for assitance and include the error message, below.");
              setFooterText("Stripe Error: " + result.data.error);
              setIsComplete(true);   
            }

          });

        } else {

          console.log('Firebase user doc not found - bailing...');
          // There was a problem
          setHeaderText("Oops! We've encountered a problem.");
          setMessageText("Unfortunately, the process did not complete successfully.  Please contact us at help@getsoovi.com for assitance and include the error message, below.");
          setFooterText("Error: User not found. (" + parsedState + ")");
          setIsComplete(true); 

        }

      });

    } else {

      console.log('Stripe query string error - bailing...');
      // There was a problem
      setHeaderText("Oops! We've encountered a problem.");
      setMessageText("Unfortunately, the process did not complete successfully.  Please contact us at help@getsoovi.com for assitance and include the error message, below.");
      setFooterText("Stripe Error: " + parsedError + " - " + parsedMessage);
      setIsComplete(true);  

    }

  }, [location.search]);
  

  return (

    <div className="App">
      <MuiThemeProvider theme={theme}>
        <CssBaseline>

          <div>                
            <Backdrop className={classes.backdrop} open={!isComplete} >
              <CircularProgress color="primary"/>      
            </Backdrop>
          </div>  

          <Container className={classes.container}>
            <Typography className={classes.header} variant="h4" color="secondary" align="center">
              {headerText}
            </Typography>
            <Typography className={classes.message} variant="body2" color="secondary" align="center">
              {messageText}
            </Typography>
            <Typography className={classes.padTop} variant="caption" color="secondary" align="center">
              {footerText}
            </Typography>       
            <Typography className={classes.padTop} variant="caption" color="primary" align="center">
              DEBUG parsedState:{dstate}, parsedCode:{dcode}, parsedError:{derror}, parsedMessage:{dmessage}
            </Typography> 
          </Container>

        </CssBaseline>
      </MuiThemeProvider>
    </div>

  )

}

export default AuthStripe;
