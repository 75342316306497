module.exports = `<!DOCTYPE html>
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0in;line-height:19.5pt;background:white'><span
style='font-size:13.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:19.5pt;background:white'><span
style='font-size:13.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>Welcome
to Soovi. We're glad you're here but please follow our rules.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>This policy was
published on October 8, 2020. It is the first version of the policy.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>1. Accepting These Terms</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>This document and
the other documents that we reference below make up our house rules, or what we
officially call our Terms of Use (the 'Terms' for short).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>The Terms are a
legally binding contract between you and Soovi. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Please note
that&nbsp;<u><span style='color:#222222'>Section 11. Disputes with Soovi</span></u>,
contains an arbitration clause and class action waiver. By agreeing to the
Terms, you agree to resolve all disputes through binding individual arbitration,
which means that you waive any right to have those disputes decided by a judge
or jury, and that you waive your right to participate in class actions, class
arbitrations, or representative actions.&nbsp;<i>*</i></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>This contract sets
out your rights and responsibilities when you use getsoovi.com, our mobile
apps, and the other services provided by Soovi (we'll refer to all of these
collectively as our 'Services'), so please read it carefully. By using any of
our Services (even just browsing one of our websites), you're agreeing to the
Terms. If you don't agree with the Terms, you may not use our Services. Agree
with us? Great, read on!</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>2. Specific Policies</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Soovi's Services
connect people to offer, sell, and buy digital media. Here's a guide to help
you understand the specific rules that are relevant for you, depending on how
you use the Services:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0in;text-indent:-.25in;
line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>'<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Our Rules for
Everyone. If you use any of our Services, you agree to these Terms, and our&nbsp;<u><span
style='color:#222222'>Privacy Policy</span></u>.<br>
<br>
</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-indent:-.25in;
line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>'<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#222222'>Our
Rules for Sellers</span></u><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>.
If you list any items for sale through our Services, these policies apply to
you:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>You
agree to provide accurate, honest information about you and your business,
including accurate contact information.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>You
agree to respond to messages from Buyers or Soovi in a timely manner.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>By
selling on Soovi you understand and agree that you are responsible for
complying with all applicable laws and regulations for the products you list
for sale, including any required labels and warnings. Soovi assumes no responsibility
for the accuracy, labeling, or content of your listings.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sellers
are charged fees for using Soovi's services. For fees associated with sales,
you agree to allow Soovi to collect its fees as part of the transaction with
the Buyer. For fees for other services, you agree to maintain a valid Credit
Card on file and allow Soovi to charge that card per the payment schedule.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>You
are responsible for protecting members' personal information you receive or
process, and you must comply with all relevant legal requirements. You agree to
not save or otherwise use Buyer phone numbers collected as part of using our
mobile app.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>If
Soovi has reason to believe you, Your Content, or your use of the Services
violate our Terms, including this Policy, we may deactivate Your Content or
suspend or terminate your account (and any accounts Soovi determines is related
to your account) and your access to the Services.<br>
<br>
</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-indent:-.25in;
line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>'<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#222222'>Our
Rules for Buyers</span></u><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>.
If you use our Services to browse or shop, these policies apply to you: </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Soovi
provides a venue for buyers to purchase from sellers of custom digital media.
It is important to note that Soovi is not a part of that transaction. By
shopping on Soovi, you understand that:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.5in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:Wingdings'>'<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>You
are not buying directly from Soovi, but from one of the many talented sellers
on Soovi;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.5in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:Wingdings'>'<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Soovi
does not pre-screen sellers and therefore does not guarantee or endorse any
items sold on Soovi or any content posted by sellers (such as photographs or
other media);</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>When
you buy from a seller on Soovi, you're directly supporting an independent
business. By making a purchase from a seller on Soovi, you agree that you have:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.5in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:Wingdings'>'<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Reviewed
all the watermarked thumbnails before making a purchase; and</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.5in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:Wingdings'>'<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Submitted
appropriate payment for your purchase.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Soovi
digital media is geared toward personal consumption and sharing. We do not
guarantee any media is appropriate for printing or large format display.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Given
the digital nature of products sold, purchases made on Soovi are final. That
said, if you have a problem with your purchase please contact us and we will
try to help.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-indent:-.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>You
may notify us of media or a seller that violates any of Soovi's policies. We
will investigate and take action as necessary.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
All of these policies are a part of our Terms, so be sure to read the ones that
are relevant for you. </span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Of course, you'll
still want to read the rest of this document because it applies to everyone!</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>3. Your Privacy</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>We know your
personal information is important to you, so it's important to us. Our&nbsp;<u><span
style='color:#222222'>Privacy Policy</span></u>&nbsp;details how your
information is used when you use our Services. By using our Services, you're
also agreeing that we can process your information in the ways set out in the
Privacy Policy, so please read it&nbsp;<u><span style='color:#222222'>here</span></u>.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Both Soovi and
sellers process members' personal information (for example, phone number) and
are therefore considered&nbsp;separate and independent data controllers&nbsp;of
buyers' personal information under EU law. That means that each party is
responsible for the personal information it processes in providing the
Services.&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>If, however, Soovi
and sellers are found to be joint data controllers of buyers' personal
information, and if Soovi is sued, fined, or otherwise incurs expenses because
of something that you did as a joint data controller of buyer personal
information, you agree to indemnify Soovi for the expenses it occurs in
connection with your processing of buyer personal information. <br>
<br>
See&nbsp;<u><span style='color:#222222'>Section 9. Indemnification (or What
Happens If You Get Us Sued)</span></u>&nbsp;below for more information about
your indemnification obligations to Soovi.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>4. Your Account with Soovi</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>You'll need to
create an account with Soovi to use some of our Services. Here are a few rules
about accounts with Soovi:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>A. Our Services
are currently only available for use in the United States of America. You must
be 18 years or older to use our Services.&nbsp;Minors under 18 and at least 13
years of age are only permitted to use our Services through an account owned by
a parent or legal guardian with their appropriate permission and under their
direct supervision. Children under 13 years are not permitted to use Soovi or
the Services. You are responsible for any and all account activity conducted by
a minor on your account. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
B. Be honest with us.&nbsp;Provide accurate information about yourself. It's
prohibited to use false information or impersonate another person or company
through your account.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
C. Choose an appropriate name.&nbsp;You agree to use your full name serve as
the name associated with your account. You may not use language that is
offensive, vulgar, infringes someone's intellectual property rights, or
otherwise violates the Terms.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
D. You're responsible for your account.&nbsp;You're solely responsible for any
activity on your account. If you're sharing an account with other people, then
the person whose financial information is on the account will ultimately be
responsible for all activity. If you're registering as a business entity, you
personally guarantee that you have the authority to agree to the Terms on
behalf of the business. Also, your accounts are not transferable.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
E. Protect your password.&nbsp;As we mentioned above, you're solely responsible
for any activity on your account, so it's important to keep your account
password secure.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
F. Let's be clear about our relationship.&nbsp;These Terms don't create any
agency, partnership, joint venture, employment, or franchisee relationship
between you and Soovi.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>5. Your Content</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Content that you
post using our Services is your content (so let's refer to it as 'Your
Content'). We don't make any claim to it, which includes anything you post
using our Services.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>A. Responsibility
for Your Content.&nbsp;You understand that you are solely responsible for Your
Content. You represent that you have all necessary rights to Your Content and
that you're not infringing or violating any third party's rights by posting it.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
B. Permission to Use Your Content. By posting Your Content through our
Services, you grant Soovi a license to use it. We don't claim any ownership to
Your Content, but we have your permission to use it to help Soovi function and
grow. That way, we won't infringe any rights you have in Your Content and we
can help promote it. For example, you acknowledge and agree Soovi may offer you
or Soovi buyers promotions on the Site, from time to time, that may relate to
your listings</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
C. Rights You Grant Soovi.&nbsp;(Here's the legalese version of the last
section). By posting Your Content, you grant Soovi a non-exclusive, worldwide,
royalty-free, irrevocable, sub-licensable, perpetual license to use, display,
edit, modify, reproduce, distribute, store, and prepare derivative works of
Your Content. This allows us to provide the Services and to promote Soovi or
the Services in general, in any formats and through any channels, including
across any Soovi Services, our partners, or third-party website or advertising
medium. You agree not to assert any moral rights or rights of publicity against
us for using Your Content. You also recognize our legitimate interest in using
it, in accordance with the scope of this license, to the extent Your Content
contains any personal information.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>That sounds like a
lot, but it's necessary for us to keep Soovi going. Consider these examples: if
you upload a photo to Soovi, we have permission to display it to buyers, and we
can watermark, resize or enhance it so it looks good to a buyer using our
mobile app; if you post a beautiful photo, we can feature it' often along with
your shop name and shop picture' on our homepage, in one of our blogs or maybe
even on a billboard to help promote your business and Soovi's.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>D. Reporting
Unauthorized Content.&nbsp;Soovi has great respect for intellectual property
rights, and is committed to following appropriate legal procedures to remove
infringing content from the Services. If content that you own or have rights to
has been posted to the Services without your permission and you want it
removed, please let us know. If Your Content is alleged to infringe another
person's intellectual property, we will take appropriate action, such as
disabling it if we receive proper notice or terminating your account if you are
found to be a repeat infringer. We'll notify you if any of that happens.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
E. Inappropriate, False, or Misleading Content.&nbsp;This should be common
sense, but there are certain types of content we don't want posted on Soovi's
Services (for legal reasons or otherwise). You agree that you will not post any
content that is abusive, threatening, defamatory, obscene, vulgar, depicts
illegal acts or otherwise offensive or any part of our Terms. You also agree
not to post any content that is false and misleading or uses the Services in a
manner that is fraudulent or deceptive.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>6. Your Use of Our Services</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>License to Use Our
Services.&nbsp;We grant you a limited, non-exclusive, non-transferable, and
revocable license to use our Services'subject to the Terms and the following
restrictions in particular:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
A. Don't Use Our Services to Break the Law.&nbsp;You agree that you will not
violate any laws in connection with your use of the Services. This includes any
local, state, federal, and international laws that may apply to you. For
example, it's your responsibility to obtain any permits or licenses that your
shop requires; you may not&nbsp;<u><span style='color:#222222'>sell anything
that violates any laws</span></u>; and you may not engage in fraud (including
false claims or infringement notices), theft, anti-competitive conduct,
threatening conduct, or any other unlawful acts or crimes against Soovi,
another Soovi user, or a third party.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>B. Pay Your
Bills.&nbsp;You are responsible for paying all fees that you owe to Soovi. <b>You
are also solely responsible for collecting and/or paying any applicable taxes
for any purchases or sales you make through our Services.</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
C. Don't Steal Our Stuff.&nbsp;You agree not to crawl, scrape, or spider any
page of the Services or to reverse engineer or attempt to obtain the source
code of the Services. <br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>D. Don't Try to
Harm Our Systems.&nbsp;You agree not to interfere with or try to disrupt our
Services, for example by distributing a virus or other harmful computer code.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
E. Follow Our Trademark Policy.&nbsp;The name &quot;Soovi&quot; and the other Soovi
marks, phrases, logos, and designs that we use in connection with our Services
(the Soovi Trademarks), are trademarks, service marks, or trade dress of Soovi
in the US and other countries. You my say your service is 'Powered by Soovi' in
advertising and promotion.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
F. Share Your Ideas.&nbsp;We love your suggestions and ideas! They can help us
improve your experience and our Services. Any unsolicited ideas or other
materials you submit to Soovi (not including Your Content or items you sell
through our Services) are considered non-confidential and non-proprietary to
you. You grant us a non-exclusive, worldwide, royalty-free, irrevocable,
sub-licensable, perpetual license to use and publish those ideas and materials
for any purpose, without compensation to you.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>G. Talk to Us
Online.&nbsp;From time to time, Soovi will provide you with certain legal
information in writing. You agree we can send you information electronically
(such as by email or SMS/MMS) instead of mailing you paper copies (it's better
for the environment), and that your electronic agreement is the same as your
signature on paper.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>7. Termination</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Termination By
You.&nbsp;We'd hate to see you go, but you may terminate your account with Soovi
at any time by emailing us. Terminating your account will not affect the
availability of some of Your Content that you posted through the Services prior
to termination. Oh, and you'll still have to pay any outstanding bills.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Termination By Soovi.&nbsp;We
may terminate or suspend your account (and any accounts Soovi determines are
related to your account) and your access to the Services should we have reason
to believe you, your Content, or your use of the Services violate our Terms. If
we do so, it's important to understand that you don't have a contractual or
legal right to continue to use our Services, for example, to sell or buy on our
websites or mobile apps. Generally, Soovi will notify you that your account has
been terminated or suspended, unless you've repeatedly violated our Terms or we
have legal or regulatory reasons preventing us from notifying you.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
If you or Soovi terminate your account, you may lose any information associated
with your account, including Your Content.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>We May Discontinue
the Services.&nbsp;Soovi reserves the right to change, suspend, or discontinue
any of the Services at any time, for any reason, including those laid out in Soovi's
policies under these Terms of Use. We will not be liable to you for the effect
that any changes to the Services may have on you, including your income or your
ability to generate revenue through the Services.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Survival.&nbsp;The
Terms will remain in effect even after your access to the Service is
terminated, or your use of the Service ends.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>8. Warranties and Limitation of Liability
(or the Things You Can't Sue Us For)</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Items You
Purchase.&nbsp;You understand that Soovi does not manufacture or inspect any of
the items sold through our Services. We provide the venue; the items in our
marketplaces are produced, listed, and sold directly by independent sellers, so
Soovi cannot and does not make any warranties about their quality, safety, or
even their legality. Any legal claim related to an item you purchase must be
brought directly against the seller of the item. You release Soovi from any
claims related to items sold through our Services, including for defective
items, misrepresentations by sellers, or items that caused physical injury
(like product liability claims).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
Content You Access.&nbsp;You may come across materials that you find offensive
or inappropriate while using our Services. We make no representations
concerning any content posted by users through the Services. Soovi is not
responsible for the accuracy, copyright compliance, legality, or decency of
content posted by users that you accessed through the Services. You release us
from all liability relating to that content.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
People You Interact With.&nbsp;You can use the Services to interact with other
individuals, either online or in person. However, you understand that we do not
screen users of our Services, and you release us from all liability relating to
your interactions with other users. Please be careful and exercise caution and
good judgment in all interactions with others, especially if you are meeting
someone in person.&nbsp;<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Third-Party
Services.&nbsp;Our Services may contain links to third-party websites or
services that we don't own or control. You may also need to use a third party's
product or service in order to use some of our Services (like a compatible
mobile device to use our mobile apps). When you access these third-party
services, you do so at your own risk. The third parties may require you to
accept their own terms of use. Soovi is not a party to those agreements; they
are solely between you and the third party.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<div align=center>

<table class=MsoNormalTable border=1 cellspacing=3 cellpadding=0
 style='background:white;border:solid #E1E3DF 1.0pt;border-bottom:solid #CBCCC9 1.0pt'>
 <tr>
  <td style='border-top:solid #E1E3DF;border-left:inset;border-bottom:solid #E1E3DF;
  border-right:inset;border-width:1.0pt;padding:9.0pt 13.5pt 9.0pt 13.5pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>WARRANTIES.&nbsp;<span
  style='color:black'>SOOVI IS DEDICATED TO MAKING OUR SERVICES THE BEST THEY
  CAN BE, BUT WE'RE NOT PERFECT AND SOMETIMES THINGS CAN GO WRONG. YOU
  UNDERSTAND THAT OUR SERVICES ARE PROVIDED 'AS IS' AND WITHOUT ANY KIND OF
  WARRANTY (EXPRESS OR IMPLIED). WE ARE EXPRESSLY DISCLAIMING ANY WARRANTIES OF
  TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
  PURPOSE, AS WELL AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE
  OF DEALING, OR USAGE OF TRADE.</span></span></p>
  <p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'><br>
  WE DO NOT GUARANTEE THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY
  PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED;
  (III) THE SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL MATERIALS; OR
  (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR EXPECTATIONS. YOU USE
  THE SERVICES SOLELY AT YOUR OWN RISK. SOME JURISDICTIONS DO NOT ALLOW
  LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
  YOU.</span></p>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>LIABILITY
  LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER SOOVI, NOR OUR
  EMPLOYEES OR DIRECTORS SHALL BE LIABLE TO YOU FOR ANY LOST PROFITS OR
  REVENUES, OR FOR ANY CONSEQUENTIAL, LLCIDENTAL, INDIRECT, SPECIAL, OR
  PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE
  TERMS. IN NO EVENT SHALL SOOVI'S AGGREGATE LIABILITY FOR ANY DAMAGES EXCEED
  THE GREATER OF ONE HUNDRED ($100) US DOLLARS (USD) OR THE AMOUNT YOU PAID SOOVI
  IN THE PAST TWELVE MONTHS. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON INCIDENTAL
  OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</span></p>
  </td>
 </tr>
</table>

</div>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif;color:white'><br>
</span><span style='font-size:16.5pt;font-family:"Times New Roman",serif'>9.
Indemnification (or What Happens If You Get Us Sued)</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>We hope this never
happens, but if Soovi gets sued because of something that you did, you agree to
defend and indemnify us. That means you'll defend Soovi (including any of our
employees) and hold us harmless from any legal claim or demand (including
reasonable attorney's fees) that arises from your actions, your use (or misuse)
of our Services, your breach of the Terms, or you or your account's
infringement of someone else's rights.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>We reserve the
right to handle our legal defense however we see fit, even if you are
indemnifying us, in which case you agree to cooperate with us so we can execute
our strategy.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>10. Disputes with Other Users</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>If you find
yourself in a dispute with another user of Soovi's Services or a third party,
we encourage you to contact the other party and try to resolve the dispute
amicably.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Case
System.&nbsp;Buyers and sellers who are unable to resolve a dispute related to
a transaction on our websites or mobile apps may participate in our case
system. Soovi will attempt to help you resolve disputes in good faith and based
solely on our interpretation of our policies, in our sole discretion; we will
not make judgments regarding legal issues or claims. Soovi has no obligation to
resolve any disputes.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Release of Soovi.&nbsp;You
release Soovi from any claims, demands, and damages arising out of disputes
with other users or parties.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>11. Disputes with Soovi</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>If you're upset
with us, let us know, and hopefully we can resolve your issue. But if we can't,
then these rules will govern any legal dispute involving our Services:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>A. Governing
Law.&nbsp;The Terms are governed by the laws of the State of Florida, without
regard to its conflict of laws rules, and the laws of the United States of
America. These laws will apply no matter where in the world you live, but if
you live outside of the United States, you may be entitled to the protection of
the mandatory consumer protection provisions of your local consumer protection
law.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
B. Arbitration.&nbsp;You and Soovi agree that any dispute or claim arising from
or relating to the Terms shall be finally settled by final and binding
arbitration, using the English language, administered by the American
Arbitration Association (the 'AAA') under its Consumer Arbitration Rules (the
&quot;AAA Rules&quot;) then in effect (those rules are deemed to be incorporated
by reference into this section, and as of the date of these Terms), unless
otherwise required by law. **Arbitration, including threshold questions of
arbitrability of the dispute, will be handled by a sole arbitrator in
accordance with those rules. Judgment on the arbitration award may be entered
in any court that has jurisdiction.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'><br>
Any arbitration or mediation under the Terms will take place on an individual
basis. You understand that by agreeing to the Terms, you and Soovi are each
waiving the right to trial by jury or to participate in a class action lawsuit.
Class arbitrations shall only be available if requested by either party under
its Class Action Arbitration Rules and approved by the arbitration entity.
Notwithstanding the foregoing, each party shall have the right to bring an
action in a court of proper jurisdiction for injunctive or other equitable or
conservatory relief, pending a final decision by the arbitrator or mediator.
You may instead assert your claim in 'small claims' court, but only if your
claim qualifies, your claim remains in such court, and your claim remains on an
individual, non-representative, and non-class basis.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>C. Costs of
Arbitration.&nbsp;Payment for any and all reasonable AAA filing,
administrative, and arbitrator fees will be in accordance with the Consumer
Arbitration Rules, and in the case of CEDR, its rules. If the value of your
claim does not exceed $10,000 USD, Soovi will pay for the reasonable filing,
administrative, and arbitrator fees associated with the arbitration, unless the
arbitrator finds that either the substance of your claim or the relief sought
was frivolous or brought for an improper purpose. For mediation through CEDR,
the parties will pay their share of mediation costs, and under certain
conditions such fees may be refundable to you, depending on the outcome of the
mediation.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>D.
Forum.&nbsp;We're based in Florida, so any legal action against Soovi related to
our Services must be filed and take place in Orange County, Florida. For all
actions under the AAA Rules, the proceedings may be filed where your residence
is, or in Orlando, Florida, and any in-person hearings will be conducted at a
location which is reasonably convenient to both parties taking into account
their ability to travel and other pertinent circumstances. For any actions not
subject to arbitration or mediation, you and Soovi agree to submit to the
personal jurisdiction of a state or federal court located in Orange County, Florida.
<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>E. Government
Exception.&nbsp;If you are a government agent or entity in the United States
using the Services in your official capacity, and you are legally unable to
agree to the clauses in this section, then those clauses do not apply to you.
In that case, the Terms and any action related to the Terms will be governed by
the laws of the United States (without reference to conflict of laws) and, in
the absence of federal law and to the extent permitted under federal law, the
laws of the State of Florida.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>F.
Modifications.&nbsp;If we make any changes to this 'Disputes with Soovi'
section after the date you last accepted the Terms, those changes will not
apply to any claims filed in a legal proceeding against Soovi prior to the date
the changes became effective. Soovi will notify you of substantive changes to
the 'Disputes with Soovi' section at least 30 days prior to the date the change
will become effective. If you do not agree to the modified terms, you may send Soovi
a written notification (including email) or close your account within those 30
days. By rejecting a modified term or permanently closing your account, you
agree to arbitrate any disputes between you and Soovi in accordance with the
provisions of this 'Disputes with Soovi' section as of the date you last
accepted the Terms, including any changes made prior to your rejection. If you
reopen your closed account or create a new account, you agree to be bound by
the current version of the Terms.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>12. Changes to the Terms</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>We may update
these Terms from time to time. If we believe that the changes are material,
we'll definitely let you know by posting the changes through the Services
and/or sending you an email or message about the changes. That way you can
decide whether you want to continue using the Services. Changes will be
effective upon the posting of the changes unless otherwise specified. You are
responsible for reviewing and becoming familiar with any changes. Your use of
the Services following the changes constitutes your acceptance of the updated
Terms.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>13. Some Finer Legal Points</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>The Terms, including
all of the policies that make up the Terms, supersede any other agreement
between you and Soovi regarding the Services. If any part of the Terms is found
to be unenforceable, that part will be limited to the minimum extent necessary
so that the Terms will otherwise remain in full force and effect. Our failure
to enforce any part of the Terms is not a waiver of our right to later enforce
that or any other part of the Terms. We may assign any of our rights and
obligations under the Terms.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal'><span style='font-size:16.5pt;
font-family:"Times New Roman",serif'>14. Contact Information</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>If you have any
questions about the Terms, please email us at&nbsp;<u><span style='color:#222222'>info@getsoovi.com</span></u>.</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>`;
