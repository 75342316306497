module.exports = `<!DOCTYPE html>
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0in;line-height:19.5pt'><span
style='font-size:13.5pt;font-family:"Times New Roman",serif'>At Soovi, we care
deeply about privacy. We believe in transparency, and we're committed to being
upfront about our privacy practices, including how we treat your personal
information. We know you care about your privacy too, so we provide settings
that allow you to choose how certain information is used by Soovi. This policy
explains our privacy practices for getsoovi.com (which we'll refer to as the
&quot;Site&quot;), Soovi's mobile applications (the &quot;Apps&quot;), and our
other services provided by Soovi, LLC (&quot;Soovi,&quot; together with
&quot;we,&quot; &quot;us,&quot; and &quot;our&quot;). We'll refer to the Site,
the Apps and our other services as the &quot;Services.&quot; This policy does
not apply to the practices of third parties (including other members who sell
using the Services) who may also collect or receive data in connection with
your use of the Services.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>This
policy was published on October 8, 2020. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Our
     Privacy Policy</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Information
     Collected or Received</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Messages
     from Soovi</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Information
     Uses, Sharing, &amp; Disclosure</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Security</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Retention</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Your
     Rights &amp; Choices</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Your
     Responsibilities</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Privacy
     Policy Changes</span></u></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><u><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Contact</span></u></li>
</ol>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>1. Our
Privacy Policy</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>We process
your personal information to run our business and provide our users with the
Services. By accepting our&nbsp;<u>Terms of Use</u>&nbsp;(and in some
jurisdictions, by acknowledging this policy), you confirm that you have read
and understand this policy, including how and why we use your information. If
you don't want us to collect or process your personal information in the ways
described in this policy, you shouldn't use the Services. We are not
responsible for the content or the privacy policies or practices of any of our
members or third-party websites and apps.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Soovi's&nbsp;<u>Terms
of Use</u>&nbsp;require all account owners to be at least 18 years of age.
Minors under 18 years of age and at least 13 years of age are permitted to use Soovi's
Services only if they have permission and direct supervision by the owner of
the account. Children under age 13 are not permitted to use the Services. You
are responsible for any and all account activity conducted by a minor on your
account. We do not knowingly 'sell,' as that term is defined under applicable
law, including the California Consumer Privacy Act, the personal information of
minors. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>By using the Services, you acknowledge that Soovi will use your
information in the United States and any other country where Soovi operates.
Please be aware that the privacy laws and standards in certain countries,
including the rights of authorities to access your personal information, may
differ from those that apply in the country in which you reside. We will
transfer personal information only to those countries to which we are permitted
by law to transfer personal information as more fully described in the
&quot;Transfers&quot; Section of this policy.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>2.
Information Collected or Received</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>In the course of providing our Services, we collect or receive
your personal information in a few different ways. We obtain the categories of
personal information listed below from the following sources: directly from
you, for example, from forms you complete or during registration; indirectly
from you based on your activity and interaction with sellers and our Services,
or from the device or browser you use to access the Services; from our vendors
and suppliers that help provide Soovi services you may interact with (such as,
for example, for payments or customer support), and from our third party
advertising and marketing partners. Often, you choose what information to
provide, but sometimes we require certain information to provide you the
Services. Soovi uses the personal information it receives and collects in
accordance with the purposes described in this policy.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Registration,
Account Setup, Service Usage:</span></i><span style='font-size:12.0pt;
font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;In order to use the
Services as a seller, you will need to submit a valid email address. If you
register, you will need to submit a name associated with your account. You need
to provide this information to enable us to provide you with the Services.
Additional information, billing and payment information (including billing
contact name, address, telephone number, credit card information), a telephone
number, and/or a physical postal address, may be necessary in order for us to
provide a particular service. As an Soovi seller, you will be required to establish
an account with Stripe and link it to you Soovi Account. Please refer to Stripe
for their Terms &amp; Privacy Policy.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Profile:</span></i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;
The name associated with your account (which you may modify in your account
settings) is publicly displayed and connected to your Soovi activity. Other
members may see the date you joined; ratings, reviews and information relating
to items you review, and related photos for items you purchased or sold; your
profile information; the number of items sold; and information you decide to
share via social networks. For Sellers, we may collect and use information
about you in order to feature you in marketing and advocacy materials, and to
feature you on and off the platform, as provided for in Soovi's Terms of Use.
From time to time, we may also ask you about certain demographic information,
such as your race, ethnicity, sexual orientation, or gender identity, in
relation to specific marketing and advocacy projects and campaigns. Providing
this demographic information is entirely voluntary and at the time we ask we
will also seek your consent to use it.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Automated
Information:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi automatically receives and records information from
your browser or your mobile device when you visit the Site, use the Apps, or
use certain features of the Services, such as your IP address or unique device
identifier, cookies, and data about which pages you visit and how you interact
with those pages in order to allow us to operate and provide the Services. This
information is stored in log files and is collected automatically. Soovi may
also receive similar information (like, for example, IP addresses and actions
taken on the device) provided by a connected Internet of Things device such as
a voice-activated assistant or Smart TV. We may combine this information from
your browser or your mobile device with other information that we or our
advertising or marketing partners collect about you, including across devices.
This information is used to prevent fraud and to keep the Services secure, to
analyze and understand how the Services work for members and visitors, and to
provide advertising, including across your devices, and a more personalized
experience for members and visitors.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>We
also automatically collect device-specific information when you install, access,
or use our Services. This information can include information such as the
hardware model, operating system information, app version, app usage and
debugging information, browser information, IP address, and device identifiers.
For more information about these online tools and how we use them, see
'Information Uses, Sharing, &amp; Disclosure' Section below.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Data
from Soovi Vendors and Suppliers:</span></i><span style='font-size:12.0pt;
font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;We also receive
information from our vendors and suppliers about you. This information can
include customer service interactions, and payments information.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Data
from Advertising and Marketing Partners:</span></i><span style='font-size:12.0pt;
font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;As described below, Soovi
receives information from our advertising and marketing partners about you.
This information can include attribution information via cookies and UTM tags
in URLs to determine where a visit to Soovi comes from, responses to marketing
emails and advertisements, responses to offers, and audience information from
partners who you have given consent to share that information with us.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Location
Information:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;We collect information about your use of the Services for
advertising, for analytics, to serve content, and to protect the Services. This
can include your IP address, browser information (including referrers), device
information (such as iOS IDFA, IDFV for limited non-advertising purposes,
Android AAID, and, when enabled by you, location information provided by your
device). When you use the Apps, you can choose to share your geolocation
details. You can choose to publish your location when you sell on Soovi.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'><br>
We obtain location information you provide in your profile or your IP address.
With your consent, we may also determine location by using other information
from your device, such as precise location information from GPS or information
about wireless networks or cell towers near your mobile device. We use and
store information about your location to provide features and to improve and
customize the Services, for example, for Soovi's internal analytics and
performance monitoring; localization, regional requirements, and policies for
the Services; for local content, search results, and recommendations; for mapping
services; and (using non-precise location information) marketing. If you have
consented to share your precise device location details but would no longer
like to continue sharing that information with us, you may revoke your consent
to the sharing of that information through the settings on the Apps or on your
mobile device. Certain non-precise location services, such as for security and
localized policies based on your IP address or submitted address, are critical
for the Site to function. We will only share your geolocation details with
third parties (like our mapping, payments, or, to the extent applicable,
advertising providers) in order to provide you with the Services. You may also
choose to enable the Apps to access your mobile device's camera to upload
photographs to Soovi.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Analytics
Information:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;We use data analytics software to ensure Site and App
functionality and to improve the Services. This software records information
such as how often you use the Apps, what happens within the Apps, aggregated
usage, performance data, app errors and debugging information, and where the
Apps were downloaded from. We do not link the information we store within the
analytics software to any personally identifiable information that you submit
within the mobile application. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Non-Member
Information:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi receives or obtains information (for example, an
email address or IP address) about a person who is not yet a registered Soovi
member (a 'non-member') in connection with certain Soovi features, such as when
a non-member engages in a transaction. Non-member information is used only for
the purposes disclosed when it was submitted to Soovi, for purposes necessary
to the functioning of Soovi's Services or where Soovi has a legitimate
interest, as disclosed in the 'Information Uses, Sharing, &amp; Disclosure'
Section below, or to facilitate action authorized by a member or the
non-member.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>3.
Messages from Soovi</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>On occasion, Soovi will need to contact you. Primarily, these
messages are delivered email or by push notifications for a variety of reasons,
including marketing, transactions, advocacy, and service update purposes. If
you no longer wish to receive push notifications, you can disable them at
device level. You can opt out of receiving marketing communications via email
or Messages in your account settings or by following the unsubscribe link in
any marketing email you receive. To ensure you properly receive notifications,
we will need to collect certain information about your device, such as
operating system and user identification information. Every account is required
to keep a valid email address on file to receive messages. Soovi may also
contact you by telephone to provide member support or for transaction-related
purposes if you request that we call you. Additionally, and with your consent
where required by applicable law, Soovi may send you an SMS (or similar)
message, or reach out to you by telephone, in order to provide you with
customer support, for research or feedback, or to provide you with information
about products and features that you may find of interest. You can update your
contact preferences in your account settings.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Some messages from Soovi are service-related and necessary for
members and Guest Checkout users. You understand and agree that Soovi can send
you non-marketing emails and SMS messages, such as those related to
transactions, your account, security, or product changes. Examples of
service-related messages include an email address confirmation/welcome email
when you register your account, notification of an order, service availability,
modification of key features or functions, relaying Messages with buyers, and
correspondence with Soovi's Support team (including by chat interface or the
other methods of communication as described above).</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>When you register for an account, subscribe to a newsletter, or
provide us with your email address or phone number such as for a Guest Checkout
purchase, you receive notice of and agree (in some jurisdictions and
situations, by an additional unambiguous consent) to receive marketing emails
and messages from us. You can unsubscribe at any time from marketing emails or
messages through the opt-out link included in marketing emails or messages.
Members can also control which marketing emails or messages they receive from Soovi
through their account settings. Please note that some changes to your account
settings may take a few days to take effect.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>4.
Information Uses, Sharing, &amp; Disclosure</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>We respect your privacy. Soovi will not disclose your name,
email address, or other personal information to third parties without your
consent, except as specified in this policy.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>You can control your privacy settings through the opt-out
buttons in your account settings privacy tab, and the Privacy Settings link in
the footer of most site pages and our homepage. Please note that some changes
to your privacy settings may take a few days to take effect. We or our sellers
may advertise our Services or our sellers' products through a variety of
different mediums and rely on your consent to do so off-site.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Legal
Bases</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>When you access or use the Services, we collect, use, share, and
otherwise process your personal information for the purposes described in this
policy. We rely on a number of legal bases to use your information in these
ways. These legal bases include where:</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Necessary
     to perform the contractual obligations in our Terms of Use and in order to
     provide the Services to you;</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>You
     have consented to the processing, which you can revoke at any time;</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Necessary
     to comply with a legal or regulatory obligation, a court order, or to
     exercise or defend any impending or asserted legal claims;</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Necessary
     for the purposes of our or a third party's legitimate interests, such as
     those of visitors, members, or partners (described in more detail below);</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>You
     have expressly made the information public;</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Necessary
     in the public interest, such as to prevent crime; and</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Occasionally
     necessary to protect your vital interests or those of others (in rare
     cases where we may need to share information to prevent loss of life or
     personal injury).</span></li>
</ul>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>We principally rely on consent (which can be withdrawn at
anytime) (i) to send marketing messages, (ii) for third-party data sharing
related to advertising, and, to the extent applicable, (iii) for the use of
location data for advertising purposes.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>We
rely on consent for targeted online and offline marketing including through
tools like&nbsp;<u>Facebook Custom Audience</u>&nbsp;and&nbsp;<u>Google
Customer Match</u>. We or our sellers may advertise our Services or our
sellers' products through a variety of different mediums and rely on your
consent to do so off-site. As part of this, we may work with advertising
partners such as Facebook, Google, and other partners. These partners have
their own privacy policies and consent mechanisms for their customers, in
addition to Soovi's controls. <br>
<br>
Soovi may also use first party data (e.g. your prior usage and behaviors on our
Site), and may combine it with other data collected from you, such as your
location, or publicly available information, to permit us to create targeted
audiences based on your known or inferred demographic and/or interests to
provide you with more relevant and useful advertising on our site, in our
direct marketing channels (like email), or with our off site marketing
partners, like Google and Facebook, subject to their terms and privacy notice
you agreed to with such partners.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><u><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Our
Legitimate Interests</span></u></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'><br>
Where we process your information on the basis of legitimate interests, we do
so as follows:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Providing
and Improving our Services:</span></i><span style='font-size:12.0pt;font-family:
"Segoe UI",sans-serif;color:#222222'>&nbsp;We use your information to improve
and customize our Services, including sharing your information for such
purposes, and we do so as it is necessary to pursue our legitimate interests of
improving our Services for our users. This is also necessary to enable us to
pursue our legitimate interests in understanding how our Services are being
used, and to explore and unlock ways to develop and grow our business. It is
also necessary to allow us to pursue our legitimate interests in improving our
Services, efficiency, interest in Services for users, and obtaining insights
into usage patterns of our Services. As a core part of our Services, we have a
legitimate interest in customizing your on-site experience to help you search
for and discover relevant items and recommended purchases, including using this
information to help sellers find the best ways to market and sell their
products on Soovi.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Providing
Marketing Communications:</span></i><span style='font-size:12.0pt;font-family:
"Segoe UI",sans-serif;color:#222222'>&nbsp;We rely on our legitimate interest
to send you marketing messages (where permitted independent of consent) and for
Soovi's advertising programs (including Soovi's on-site advertising and
marketing).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Keeping
our Services Safe and Secure:</span></i><span style='font-size:12.0pt;
font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;We use your information
for safety and security purposes, which can include sharing your information
for such purposes, and we do so because it is necessary to pursue our
legitimate interests or those of a third party in ensuring the security of our
Services, preventing harm to individuals or property, or crime, enforcing or
defending legal rights, or preventing damage to Soovi's systems, or those of
our users or our partners. This includes enhancing protection of our community
against spam, harassment, intellectual property infringement, crime, and
security risks of all kinds. We use your information to provide and improve the
Services, for billing and payments, for identification and authentication, and
for general research and aggregate reporting. For example, in accordance with
our existing&nbsp;<u>case resolution process</u>, we may use information you
provide to resolve disputes with Soovi or other members.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Buying
and Selling:&nbsp;</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>As part of the buying and selling process, Soovi will facilitate
the sharing of information between the two members involved in the transaction,
such as the other Soovi member's payment status. This can also involve us
sharing your information with some of our third party partners such as our
payment partners to enable us to provide the Service to you. Such partners will
process your personal information in accordance with their own privacy
policies. In some instances, while they are subject to Soovi's privacy and
security requirements for vendors, these partners, not Soovi, are responsible
for the protection of personal information under their control. By making a
sale or a purchase on Soovi, you are directing us to share your information in
this way. Since this is an important part of the Services we provide, we need
to do this in order to perform our obligations under our Terms of Use. We
expect you to respect the privacy of the member whose information you have
received. As described in Soovi's&nbsp;<u>Terms of Use</u>, you have a limited
license to use that information only for Soovi-related communications or for Soovi-facilitated
transactions. Soovi has not granted a license to you to use the information for
unauthorized transactions or sending unsolicited commercial messages in
violation of any applicable laws, including any consent requirements of the
jurisdiction of the recipient. You should only add a member to your email or
physical mailing list or otherwise use or store a member's personal information
in accordance with applicable laws, including any consent requirements that
apply in that member's jurisdiction.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Site
Customization:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;We may learn about the types of products that you're
interested in from your browsing and purchasing behavior on (and off) the Site
or Apps and use such information to suggest potential purchases as a result. As
a core part of our Services, we have a legitimate interest in customizing your
on-site experience to help you search for and discover relevant items and
recommended purchases, including using this information to help sellers find
the best ways to market and sell their products on Soovi.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Legal
and Safety:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi may also retain, preserve, or release your personal
information to a third party in the following limited circumstances: in
response to lawful requests by public authorities, including to meet legitimate
national security or law enforcement requirements; to protect, establish, or
exercise our legal rights or defend against impending or asserted legal claims,
including to collect a debt, or a material violation of our policies (including
our Intellectual Property Policy); to comply with a subpoena, court order,
legal process, regulation, or other legal requirement; or when we believe in
good faith that such disclosure is reasonably necessary to comply with the law,
prevent imminent physical harm or financial loss, or investigate, prevent, or take
action regarding illegal activities, suspected fraud, threats to our rights or
property, or violations of Soovi's&nbsp;<u>Terms of Use</u>. For example, if
permitted due to the forgoing circumstances, your information may be shared
with tax authorities or other governmental agencies. In the cases set out
above, our use of your information may be necessary for the purposes of our or
a third party's legitimate interest in keeping our Services secure, preventing
harm or crime, enforcing or defending legal rights, or preventing damage. Such
use may also be necessary to comply with a legal obligation, a court order, or
to exercise or defend legal claims or to facilitate the collection of taxes and
prevention of tax fraud. It may also be necessary in the public interest (such
as to prevent crime) or to protect vital interests (in rare cases where we may
need to share information to prevent loss of life or personal injury).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>If Soovi
receives a lawful, verified request for a member's records or information in
one of the limited circumstances described in the previous paragraph, Soovi may
disclose personal information, which may include, but may not be limited to, a
member's name, address, phone number, email address, company/trading name and,
where appropriate, bank account and transaction details. </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Affiliated
Businesses:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi is affiliated with a variety of businesses and works
closely with them for a variety of purposes, including assisting us to perform
and improve the Services. These businesses may sell items or services to you
through the Services or, with your consent, offer promotions (including email
promotions) to you. Soovi also provides services or sells products jointly with
affiliated businesses, including providing you with the opportunity to sign up
with such partners and allow them to more effectively market to you. When an
affiliated business assists in facilitating your transaction, we may need to
share information related to the transaction with that affiliated business in
order to facilitate your transaction, and this forms part of the Services we
provide in accordance with our Terms of Use. We rely on consent (which can be
withdrawn at any time) to send marketing messages and for third-party sharing
relating to advertising. As part of this, we may work with advertising partners
such as Facebook or Google, and we may use analytics aggregated from usage
information including, for example, search keywords, favorites, browsing
history, and purchase history.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Aggregated
Information:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi may share demographic information with business
partners, but it will be aggregated and de-personalized so that personal
information is not revealed.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Service
Providers:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Soovi also needs to engage third-party companies and
individuals (such as research companies, and analytics and security providers)
to help us operate, provide, and market the Services. These third parties have
only limited access to your information, may use your information only to
perform these tasks on our behalf, and are obligated to Soovi not to disclose
or use your information for other purposes. Our engagement of service providers
is often necessary for us to provide the Services to you, particularly where
such companies play important roles like helping us keep our Service operating
and secure. In some other cases, these service providers aren't strictly
necessary for us to provide the Services, but help us make it better, like by
helping us conduct research into how we could better serve our users. In these
latter cases, we have a legitimate interest in working with service providers
to make our Services better.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Business
Reorganization:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;In some cases, Soovi may choose to buy or sell assets.
Such transactions may be necessary and in our legitimate interests,
particularly our interest in making decisions that enable our business to
develop over the long term. In these types of transactions (such as a sale,
merger, liquidation, receivership, or transfer of all or substantially all of Soovi's
assets), member information is typically one of the business assets that is
transferred. If Soovi intends to transfer information about you, Soovi will
notify you by email or by putting a prominent notice on the Site and the App,
and you will be afforded an opportunity to opt out before information about you
becomes subject to a different privacy policy.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Third
Parties:</span></i><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>&nbsp;Third-party plug-ins also collect information about your
use of the Site. For example, when you load a page on Soovi that has a social
plug-in from a third-party site or service, such as a 'Like' or 'Send' button,
you are also loading content from that third-party site. That site may request
cookies directly from your browser. These interactions are subject to the
privacy policy of the third-party site. In addition, certain cookies and other
similar technologies on the Site are used by third parties for targeted online
marketing and other purposes. These technologies allow a partner to recognize
your computer or mobile device each time you use the Services. Please be aware
that when you use third-party sites or services, their own terms and privacy
policies will govern your use of those sites or services. Soovi chooses and
manages these third-party technologies placed on its Sites and Apps. However,
these are third-party technologies, and they are subject to that third party's
privacy policy. We rely on your consent to drop and read these cookies when not
technically necessary or when not required based on another purpose such as
legitimate interest.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>This policy does not apply to the practices of third parties
(such as other members who sell using the Services, certain third-party
providers on whom we rely to provide certain services, or API users) that Soovi
does not own or control or individuals that Soovi does not employ or manage,
except as otherwise provided for in the Terms or as required by law. If you
provide your information to such third parties in connection with your use of
the Services, different practices may apply to the use or disclosure of the
information that you provide to them. Soovi does not control the privacy or
security policies of such third parties. To the full extent applicable in your
jurisdiction, Soovi is not responsible for the privacy or security practices of
these sellers or other websites on the internet, even those linked to or from
the Services. We encourage you to read the privacy policies and ask questions
of third parties before you disclose your personal information to them. For the
purposes of European law, these sellers and third party providers 'are
independent controllers of data, which means that they are responsible for
providing and complying with their own policies relating to any personal
information they obtain in connection with the Services.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>5.
Security</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>The security of your personal information is important to us. We
follow generally accepted industry standards to protect the personal
information submitted to us, both during transmission and after it is received.
Unfortunately, no method of transmission over the internet or method of
electronic storage is 100% secure. Therefore, while we strive to protect your
personal information, we cannot guarantee its absolute security.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Your account information is protected by a password. It is
important that you protect against unauthorized access to your account and
information by choosing your password carefully and by keeping your password
and computer secure, such as by signing out after using the Services. </span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>If you have any questions about the security of your personal
information, you can contact us at info@getsoovi.com.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>6.
Retention</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Soovi will retain your information only for as long as is
necessary for the purposes set out in this policy, for as long as your account
is active (i.e., for the lifetime of your Soovi member account), or as needed
to provide the Services to you. If you no longer want Soovi to use your
information to provide the Services to you, you may close your account. Soovi
will retain and use your information to the extent necessary to comply with our
legal obligations (for example, if we are required to retain your information
to comply with applicable tax/revenue laws), resolve disputes, enforce our
agreements, and as otherwise described in this policy. In addition, Soovi
sellers may also be required to retain and use your information in order to
comply with their own legal obligations. Please note that closing your account
may not free up your email address or other identifiers for reuse on a new
account. We also retain log files for internal analysis purposes. These log
files are generally retained for a brief period of time, except in cases where
they are used for site safety and security, to improve site functionality, or
we are legally obligated to retain them for longer time periods.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>7.
Your Rights &amp; Choices</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Certain privacy laws such as the California Consumer Privacy Act
(CCPA), provide users with rights related to their personal information.
Consistent with those laws, Soovi gives you the choice of accessing, editing,
or removing certain information, as well as choices about how we contact you.
You may change or correct your Soovi account information through your account
settings, if available, or by contacting us. You can also request to permanently
close your account and delete your personal information. Depending on your
location, you may also benefit from a number of rights with respect to your
information. While some of these rights apply generally, certain rights apply
in limited cases.</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Correction: You have the right to request that we rectify inaccurate
     information about you. </span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Restrict Processing: In certain cases where we process your
     information, you may also have the right to restrict or limit the ways in
     which we use your personal information.</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Deletion: In certain circumstances, you have the right to request the
     deletion of your personal information, except information we are required
     to retain by law, regulation, or to protect the safety, security, and integrity
     of Soovi. </span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Withdraw Consent: Where we rely on consent, you can choose to withdraw
     your consent to our processing of your information using specific features
     provided to enable you to withdraw consent, like an email unsubscribe link
     or your account privacy preferences. If you have consented to share your
     precise device location details but would no longer like to continue
     sharing that information with us, you can revoke your consent to the
     sharing of that information through the settings on your mobile device.
     This is without prejudice to your right to generally permanently close
     your account and delete your personal information.<br>
     <br>
     </span></li>
</ul>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>The CCPA provides California residents with the following
additional rights:</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Know: California residents may request disclosure of the specific
     pieces and/or categories of personal information that the business has
     collected about them, the categories of sources for that personal
     information, the business or commercial purposes for collecting the information,
     the categories of personal information that we have disclosed, and the
     categories of third parties with which the information was shared.</span></li>
 <li class=MsoNormal style='color:#222222;margin-bottom:0in;line-height:normal;
     background:white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif'>Right
     to Opt-Out: To the extent that Soovi 'sells' personal information (as that
     term is defined under the CCPA), California residents are entitled to
     opt-out of the 'sale' of data at any time (see below for more
     information).</span></li>
</ul>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'><br>
If you would like to manage, change, limit, or delete your personal
information, you can do so by contacting us. Once you contact us to exercise
any of your rights, we will confirm receipt of your request.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Limiting use of, or deleting, your personal information may
impact features and uses that rely on that information. However, we will not
discriminate against you for exercising any of your rights, including otherwise
denying you goods or services, providing you with a different level or quality
of services, or charging you different prices or rates for services. If you
need further assistance, you can contact Soovi through one of the channels
listed below under 'Contact'. We will respond to your request within a
reasonable timeframe.</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Please note that we may verify your identity before we are able
to process any of the requests described in this Section, and in our
discretion, deny your request if we are unable to verify your identity. As a
part of this process, government or other identification may be required. You
may designate an authorized agent to make a request on your behalf. In order to
designate an authorized agent to make a request on your behalf, you must
provide a valid power of attorney, the requester's valid government issued
identification, and the authorized agent's valid government issued
identification, and we may verify the authenticity of the request directly with
you.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Email
&amp; Messages</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Soovi
may send you messages about the Services or your activity. Some of these
messages are required, service-related messages for members (such as
transactional messages or legal notices). Other messages are not required, such
as newsletters. You can control which optional messages you choose to receive
by contacting us, and you can learn more in the 'Messages from Soovi' Section
of this policy. If you no longer wish to use the Services or receive
service-related messages (except for legally required notices), then you may
close your account.&nbsp;Contact us to learn how to close your account.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Cookies</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>We use
both technically necessary (for the functioning and security of the Services)
and non-technically necessary cookies and similar technologies.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Additional
Disclosures for California Residents</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Notice
of Collection</span></i></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>In addition to the Rights &amp; Choices described above, the
CCPA requires disclosure of the categories of personal information collected
over the past 12 months. Soovi is new and will offer this information when a
year of operation has passed.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>The
Purposes for Our Collection</span></i></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>We collect and use these categories of personal information for
our business and commercial purposes described in the 'Information Uses,
Sharing and Disclosure' Section above, including providing and improving the
Services, maintaining the safety and security of the Services, processing
purchase and sale transactions, and for advertising and marketing services.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Third
Party Marketing and Advertising and Your Rights (Opt-Out of 'Sale')</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Soovi
does not sell personal information to third parties for monetary value.
However, the term 'sale' is defined broadly under the California Consumer
Privacy Act. To the extent that 'sale' under the CCPA is interpreted to include
interest based advertising or other data uses described in the 'Information
Uses, Sharing and Disclosure' Section above, we will comply with applicable law
as to those activities. </span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'><br>
To opt-out of receiving interest based advertising, you can exercise your choice
by contacting us. The right to opt-out of interest based advertising is
available to all Soovi users.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Do Not
Track Signals</span></i></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>Your browser settings may allow you to automatically transmit a
'Do Not Track' signal to online services you visit. Note, however, there is no
industry consensus as to what site and app operators should do with regard to
these signals. Accordingly, unless and until the law is interpreted to require
us to do so, we do not monitor or take action with respect to 'Do Not Track' signals.
For more information on 'Do Not Track,' visit http://www.allaboutdnt.com.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Shine
the Light</span></i></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>California
law entitles residents to ask for a notice describing what categories of
personal information we share with third parties for their own direct marketing
purposes. Other than to Sellers on Soovi (see 'Buying and Selling' under the
'Information Uses, Sharing and Disclosure' Section above), who must obtain your
separate consent for their own marketing off of Soovi, or unless you request us
to or consent to it, Soovi does not share any personal information to third
parties for their own direct marketing purposes. If you have questions about
these practices, you can contact us through one of the channels listed in the
'Contact' Section below.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><i><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>Accessibility</span></i></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>If you have a disability and would like to access this policy in
an alternative format, please contact us using one of the channels listed in
the 'Contact' section below.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>8.
Your Responsibilities</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;color:#222222'>If you
sell using our Services, you may receive and determine what to do with certain
personal information. Your privacy responsibilities, including when you act as
an independent data controller (someone who decides what personal data to
collect and the purpose you use the data for) are laid out in the&nbsp;<u>Soovi
Terms of Use</u>.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>9.
Privacy Policy Changes</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>We may amend or update this policy from time to time. If we
believe that the changes are material, we'll let you know by doing one (or
more) of the following: (i) posting the changes on or through the Services,
(ii) sending you an email or message about the changes, or (iii) posting an
update in the version notes on the Apps' platform. We encourage you to check
back regularly and review any updates.</span></p>

<p class=MsoNormal style='margin-top:27.0pt;margin-right:0in;margin-bottom:
9.0pt;margin-left:0in;line-height:normal;background:white'><span
style='font-size:16.5pt;font-family:"Segoe UI",sans-serif;color:#222222'>10.
Contact</span></p>

<p class=MsoNormal style='margin-bottom:.25in;line-height:normal;background:
white'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:#222222'>If you have any questions contact Soovi's Support team&nbsp;at info@getsoovi.com</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>`;
