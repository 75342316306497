import React, { useState, useEffect } from 'react';

import { navigate } from "@reach/router";

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
//import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import logo from './assets/logo180.png';
import stripeLogo from './assets/stripe.png';
import 'fontsource-roboto';
import './App.css';

import { useStripe } from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
//import CardSection from './CardSection'

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import PrivacyDialog from './privacy';
import TermsDialog from './terms';

import * as firebase from 'firebase';
//import { colors } from '@material-ui/core';
require("firebase/functions");


let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#61C1A1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ef6c00',
      contrastText: '#fff',
    },
  }
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    paddingTop: '100%', // 1:1 
  },
  card: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  titleIcon: {
    flexGrow: 1,
  },
  header: {
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(0),
    backgroundColor: '#61C1A1',
    color: '#fafafa'
  },
  main: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    backgroundColor: '#DCDCDC',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#DCDCDC',
  },
  footer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    backgroundColor: '#61C1A1',
    color: '#fafafa'
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  footerRowItem: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center'
  },
  copyright: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#ef6c00'
  },
  loading: {
    paddingTop: theme.spacing(3), 
    paddingBottom: theme.spacing(3),
  },
  padTop: {
    paddingTop: theme.spacing(3),
  },
  padBottom: {
    paddingBottom: theme.spacing(1),
  },
  padMargins: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    alignItems: 'center',
    alignContent: 'center'
  },
  dialogTitle: {
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#61C1A1',
    color: '#fafafa'
  },
  dialogClose: {
    paddingLeft: theme.spacing(3),
    justifyContent: 'left'
  },
  dialogItem: {
    flexDirection: 'column',
    justifyContent: 'top',
    paddingTop: theme.spacing(2)
  },
  stripeButton: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  stripeCallToAction: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  stripeFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }

});


function App(props) {

  const [photos, setPhotos] = useState(null);
  const [expiryDate, setExpiryDate] = useState();
  const [purchased, setPurchased] = useState(false);
  const [clientStripe, setClientStripe] = useState();
  const [price, setPrice] = useState();

  const [buyWait, setBuyWait] = useState(false);  // control display of circular progress when user click Buy Now
  const [stripeDialogShow, setStripeDialogShow] = useState(false);  // passed by callback from Stripe Checkout
  const [stripeDialogTitle, setStripeDialogTitle] = useState();
  const [stripeDialogBody, setStripeDialogBody] = useState();

  
  // initialize firestore
  const db = firebase.firestore();  
  var user = firebase.auth().currentUser;
  if (!user) {
    firebase.auth().signInAnonymously().catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('App - Auth error ', errorCode, ': ', errorMessage);
        alert('Auth error: ' + errorMessage);
    });
  }

  // initalize stripe
  const stripe = useStripe();
  //const elements = useElements();

  // get session from Router prop
  const session = props.sid;
 
  // retrieve the session document
  useEffect(() => {
    console.log('App - useEffect');

    if (props.status === 'success') {
      firebase.analytics().logEvent('stripe_redirect_success',{
        session: session
      });
      setStripeDialogTitle('THANK YOU!');
      setStripeDialogBody('Press OK to see your photos. If the watermarks are still visible, please wait a minute and reload the page. If you have any trouble, contact us for help.') 
      setStripeDialogShow(true);
    }
    else if (props.status === 'failure') {
      firebase.analytics().logEvent('stripe_redirect_failure',{
        session: session
      });
      setStripeDialogTitle('Oops!');
      setStripeDialogBody('You canceled the transaction or there was a problem with your purchase. Your card was not charged. Please try again or contact us for help.') 
      setStripeDialogShow(true);
    }
    else {
      firebase.analytics().logEvent('session_view',{
        session: session
      });
    }
    
    db.collection('sessions').doc(session).get()
    .then(result => {
      if (result.exists) {

        // calculate and set the expiry date
        var tmpDate = result.data().uploaded.toDate();
        tmpDate.setDate(tmpDate.getDate() + 15);
        setExpiryDate(tmpDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));

        var price = parseFloat(result.data().sku);
        var displayPrice = price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        setPrice(displayPrice);
        console.log('price ', price, ' displaPrice ', displayPrice);

        // save the appropriate photo url's based on purchase state
        if (result.data().purchased) {
          // purchased photos
          setPhotos(result.data().photoArray);
          setPurchased(true);
        } else {
          // watermarked thumbs
          setPhotos(result.data().thumbArray);      
          setPurchased(false);;

          // Get the client's Stripe ID      
          db.collection('soovineers').doc(result.data().userid).get()
          .then(sresult => {
            if (sresult.exists) {
              setClientStripe(sresult.data().stripeid);
            }
          });

        }
      } else {
        firebase.analytics().logEvent('session_not_found',{
          session: session
        });
        alert("Sorry, photos not found. If they're new, wait a momement and try again. Otherwise they may have expired.");
      }
    }).catch((error) => {
      firebase.analytics().logEvent('session_load_error',{
        session: session,
        message: error.message
      });
      alert("There was a problem loading the session: " + error.message + ". Wait a moment and try again.");
    });
    
  }, [db, props, session]);

  // options for lightbox
  const srlOptions = {
    settings: {
      disablePanzoom: true,
      hideControlsAfter: false,
    },
    caption: {},
    buttons: {
      showAutoplayButton: false,
      showFullscreenButton: true, 
      showThumbnailsButton: false,
      showDownloadButton: purchased ? true : false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    progressBar:{}
  }

  const classes = useStyles();

  const puchasedHeader = "Enjoy your photos!";
  const unpurchasedHeader = "Buy your photos today!";

  const purchasedFooter = "Please download them before that date.";
  const unpurchasedFooter = "Purchase and download before that date.";

  const purchasedAlt = "Remember to download your photos!";
  const unpurchasedAlt = "Purchase to remove watermark & download full-res photos";

  const purchasedMedia = "tap a photo to view & download";
  const unpurchasedMedia = "tap a thumbnail to view";

  const unpurchasedMediaCTA = " ";
  const purchasedMediaCTA = " "  

  const purchasedStripe = "Thanks for your purchase!";
  const unpurchasedStripe = "Buy with confidence";


  // ***************
  // STRIPE CHECKOUT
  // ***************

  // Stripe Checkout Session
  async function stripeGetCheckoutSession() {

    const payload = {
      session: session,
      currency: 'usd',
    }

    var checkoutSession = firebase.functions().httpsCallable('createCheckoutSession');
    return checkoutSession(payload).then((result) => {

      console.log('createCheckoutSession returned ', result);
      return result;
    
    });

  }


  async function handleStripeCheckout() {

    setBuyWait(true);
    firebase.analytics().logEvent('stripe_checkout_pressed',{
      session: session,
      price: price
    });
    const stripeSession = await stripeGetCheckoutSession();
    console.log ('createCheckoutSession - stripeSession returned ', stripeSession);

    stripe.redirectToCheckout({
      sessionId: stripeSession.data.id
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      alert('Checkout error: ' + result.error.message);
      firebase.analytics().logEvent('stripe_checkout_error',{
        session: session,
        message: result.error.message
      });
      setBuyWait(false);
    });

  }

  function sendHelpEmail() {
    firebase.analytics().logEvent('help_email_pressed',{
      session: session
    });
    var mail = document.createElement("a");
    mail.href = "mailto:info@getsoovi.com?subject=Soovi%20Help%20(" + session + ")";
    mail.click();
  }
  
  return (
      <div className="App">
        <SimpleReactLightbox>
          <MuiThemeProvider theme={theme}>
            <CssBaseline>
          
              <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense" >                
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => navigate(`/r/${session}`)}>
                    <img src={logo} height="50" alt="logo" /> 
                  </IconButton>
                  <Typography className={classes.titleIcon}></Typography>
                    {!purchased && <Button variant="outlined" color="secondary" onClick={() => handleStripeCheckout()}>
                      BUY NOW {price}
                    </Button> }
                </Toolbar>
              </AppBar>            
            
              <header className={classes.header}>     

                <Typography  variant="h4" align="center">
                  { purchased ? puchasedHeader : unpurchasedHeader }
                </Typography>
                <Typography variant="caption" align="center" display="block" >
                    { purchased ? purchasedMediaCTA : unpurchasedMediaCTA }
                  </Typography>

              </header>

              <main className={classes.main}>

                {!photos ?
                
                <Typography className={classes.loading} variant="h6" color="secondary" align="center">
                  LOADING PHOTOS...
                </Typography>
              
                :

                <Container className={classes.container} maxWidth="md">
                  <SRLWrapper options={srlOptions}> 
                    <Grid container spacing={1}>                       
                      {photos && photos.map((image, index) => (
                        <Grid item key={index} xs={6} sm={3} md={3}>
                          <Card>
                            <CardMedia
                              component="img"
                              alt={ purchased ? purchasedAlt : unpurchasedAlt }
                              height="175"
                              image={image}  
                              src={image}
                                                                        
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </SRLWrapper>
                
                  <Typography variant="caption" align="center" display="block" >
                    { purchased ? purchasedMedia : unpurchasedMedia }
                  </Typography>                  
                </Container>

                }

              </main>         

              <footer >

                <div className={classes.footer}>
                  <Typography className={classes.padMargins} variant="body2"  align="center">
                    These photos expire on <b>{expiryDate}</b>. 
                  </Typography>
                  <Typography className={classes.padMargins} variant="caption" display="block"  align="center">
                    { purchased ? purchasedFooter : unpurchasedFooter }
                  </Typography>
                </div>          

                <div className={classes.footerRow}>
                  <div className={classes.footerRowItem}>
                    <TermsDialog/>
                  </div>

                  <div className={classes.footerRowItem}>
                    <PrivacyDialog/>
                  </div>

                  <Button color="primary" align="center"  className={classes.footerRowItem} onClick={() => sendHelpEmail()}>
                    Need Help?
                  </Button> 
                </div>

                <div >
                  <Typography color="secondary" variant="caption"  gutterBottom>
                    { purchased ? purchasedStripe : unpurchasedStripe }
                  </Typography> 
                  <IconButton component="span" onClick={() => window.open("https://www.stripe.com/customers", "_blank")}>
                    <img src={stripeLogo} alt="stripe" height="20" />
                  </IconButton>
                </div>

                <div className={classes.copyright}>
                  <Typography color="primary" variant="caption" align="center" className={classes.footerRowItem}>
                    copyright © 2020 soovi, llc - all rights reserved.
                  </Typography> 
                  <Typography color="primary" variant="caption" align="center" className={classes.footerRowItem}>
                    made with &hearts; in Orlando.
                  </Typography> 
                </div>
                
              </footer>
              
              <div>                
                <Backdrop className={classes.backdrop} open={buyWait} >
                  <CircularProgress color="primary"/>      
                </Backdrop>
              </div>   

              <div>
                <Dialog                                    
                  PaperProps={{
                    style: {
                      backgroundColor: '#eeeeee',
                    }
                  }}
                  open={stripeDialogShow}
                  close={() => navigate('/' + session + '/')}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogContent>
                    <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{stripeDialogTitle}</DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                      {stripeDialogBody}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => navigate('/' + session + '/')} color="primary" >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>                     
              
            </CssBaseline>
          </MuiThemeProvider>
        </SimpleReactLightbox>
      </div>
  );
}

export default App;
