import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Static from './Static';
import AuthStripe from './AuthStripe'; //WASTED DAY?
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { Router } from "@reach/router";

const config = {
  apiKey: "AIzaSyB1VP4_6jAVEUTz_vEluv7_KWCXEy_iZHE",
  authDomain: "soovi-11ecd.firebaseapp.com",
  databaseURL: "https://soovi-11ecd.firebaseio.com",
  projectId: "soovi-11ecd",
  storageBucket: "gs://soovi-11ecd.appspot.com/",  
  appId: "1:496510602250:web:12497976ed6c1574",
  measurementId: "G-T85FN1PBTQ"
};
firebase.initializeApp(config);
firebase.analytics();

// Initialize Stripe for payment
// TODO CONVERT TO ENV VARIABLE
const STRIPE_PUBLISHABLE_KEY = 'pk_test_51GruDAJuzqr5bkMYOf6B7UZLbYcP0M0Vsa2TderutcSKyclCn7QsWyyR8V1aLu1z4BTBMXdk0206JlEf8y6IxlCo00FBM4AQnF';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function Base() {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <AuthStripe path='/connect/oauth' />
        <Static path='/' />
        <Static path='/r/:sid' />
        <App path='/:sid' />
        <App path='/:sid/:status' />        
      </Router>
    </Elements>
  );
};

ReactDOM.render(<Base />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
