import React from 'react';

import { navigate } from "@reach/router";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import logo from './assets/logo180.png';
import './App.css';

import PrivacyDialog from './privacy';
import TermsDialog from './terms';

function Static(props) {
  return (
    <div className="App">
      <header className="App-header">
        <Typography className="Title" variant="h4">
          Limited Release
        </Typography>
        <img src={logo}  alt="logo" />
        <p className="Byline" >
          <code>Become a Souvineer!</code>
        </p>
        <PrivacyDialog/>
        <TermsDialog/>
        <p>
          { props.sid && <Button color="primary" onClick={() => navigate('/'+props.sid)}>click here to return to your photos</Button> }
        </p>
      </header>
    </div>
  );
}

export default Static;
